import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'

import isOutside from '@/directive/isOutside'
import validity from '@/directive/Validity'
import Popup from '@/components/common/Popup'
import SpSelect from '@/components/common/input/SpSelect'

// import './mixin/gtm'
import VueGtm from '@gtm-support/vue2-gtm'
import './mixin/Common'
import './assets/scss/common.scss'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/] // https://ionic.io/ionicons

const API_TIMEOUT = 35000
Vue.prototype.$http = axios.create({
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

Vue.use(VueGtm, {
  id: 'GTM-PCBZR6XN', // GTM 컨테이너 ID
  defer: false, // 스크립트를 'defer'로 로드할지 여부 (기본값: false)
  enabled: true, // 플러그인 활성화 여부 (기본값: true)
  debug: false, // 디버그 모드 활성화 여부 (기본값: false)
  loadScript: true, // GTM 스크립트를 자동으로 로드할지 여부 (기본값: true)
  vueRouter: router, // Vue Router 인스턴스 (자동 페이지뷰 추적에 필요)
  trackOnNextTick: false, // Vue.nextTick에서 페이지뷰를 추적할지 여부 (기본값: false)
})

Vue.use(isOutside)
Vue.use(validity)
Vue.use(PortalVue)
Vue.component('Popup', Popup)
Vue.component('SpSelect', SpSelect)

const v = new Vue({
  router,
  store,
  created () {
    this.$http.interceptors.request.use(
      (config) => {
        const source = axios.CancelToken.source()
        config.cancelToken = source.token
        store.commit('addCancelToken', source)
        store.commit('increaseRequestCount')
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    this.$http.interceptors.response.use(
      this.responseSuccess,
      this.responseError,
    )
  },
  render: h => h(App),
})

router.onReady(() => {
  v.$mount('#app')
})
